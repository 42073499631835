
.container {
    height: 100%;

}
.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 24px 0;
    font-size: 18px;
}
.child {
    display: flex;
    align-items: center;
    column-gap: 68px;

}
.btnBox {
    display: flex;
    align-items: center;
    column-gap: 16px;
}
.btnBox button {

    font-size: 13px;
    color: rgb(56, 139, 255);
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}

.content {
    max-width: 750px;
    height: calc(100% - 112px);
    overflow-y: auto;
}
.content::-webkit-scrollbar {
    width: 0;
}
.item {
    margin-bottom: 16px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
}

.item_header, .item_child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 36px;
    padding: 14px 20px;
    background-color: #fafafa;
}
.item_child {
    padding: 12px 20px;
    font-size: 14px;
    border-top: 1px solid #f0f0f0;
    background-color: transparent
}
.inputBox {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 20px 0;
}
.label {
    flex-shrink: 0;
}

