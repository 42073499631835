
.container {
    height: 100%;
}
.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 24px 0;
    font-size: 18px;
}
.inputBox {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 16px 0;
}
.label {
    flex-shrink: 0;
}
.quillBox {
    height: 480px;
}

.headRight {
    display: flex;
    column-gap: 12px;
}

