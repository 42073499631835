
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    background-image: url('../../assets/image/login_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.container::after,
.container::before {
    content: '';
    flex-grow: 1;
    min-height: 16px;
}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 328px;
}
.titleBox {
    display: flex;
    column-gap: 12px;
    margin-bottom: 12px;
}
.loginImg {
    width: 44px;
    height: 40px;
}
.loginImg img {
    width: 100%;
    height: auto;
}
.title {
    font-size: 32px;
}
.subTitle {
    margin-bottom: 48px;
    text-align: center;
}
