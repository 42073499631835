
.content {
    max-height: 648px;
    padding-right: 6px;
    overflow-y: auto;
}
.content::-webkit-scrollbar {
    width: 4px;
}
.content::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, .1);
}
