
.layout {
    display: flex;
    height: 100%;
    min-width: 1200px;
    background-color: #f5f5f5;
}

.sidebarHead {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 56px;
    padding-left: 28px;
    border-bottom: 1px solid #f0eded;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
}
.iconBox {
    width: 32px;
    height: 28px;
}
.iconBox img {
    width: 100%;
    height: auto;
}
.content {
    width: calc(100% - 256px);
}
.contentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid #f0eded;
    background: #fff;
}
.headLeft {
    font-weight: 600;
}

.sidebar {
    width: 256px;
    height: 100%;
    border-right: 1px solid #f0eded;
}
.navbar {
    height: calc(100% - 56px);
    overflow-y: auto;
    background: #fff;
}
.navbar::-webkit-scrollbar {
    width: 5px;
}
.navbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .3);
    border-radius: 6px;
}
.routeContent {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.routeMain {
    width: calc(100% - 48px);
    height: calc(100% - 36px);
    padding: 16px 24px;
    background-color: #fff;
    border-radius: 8px;
}

