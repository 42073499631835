
.content {
    width: 100%;
    max-width: 1024px;
    height: calc(100% - 32px);
}
.title {
    padding: 0 0 24px 0;
    font-size: 18px;
}

.quillBox {
   height: calc(100% - 148px);
}

.btnBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}
