
.container {
    height: 100%;
}
.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 16px 0;
    font-size: 18px;
}
.formBox {
    max-height: 724px;
    padding-right: 4px;
    overflow-y: auto;
}
.formBox::-webkit-scrollbar {
    width: 5px;
}
.formBox::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .3);
}
.title {
    font-size: 16px;
}
.btnBox {
    margin-top: 24px;
    text-align: end;
}
.query {
    display: flex;
    column-gap: 24px;
    margin: 16px 0 24px;
    font-size: 14px;
}
.image_box {
    margin-bottom: 8px;
}
