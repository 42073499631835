

.content {
    margin-top: 12px;
}
.header {
    padding: 0 16px;
}

.main {
    padding: 0 16px 16px;
    margin-bottom: 16px;
    border-bottom: 1px dashed #d7d7d7;
}
.selectBox {
    display: flex;
    column-gap: 24px;
}
.inputBox {
    margin-bottom: 16px;
}
.label {
    margin-bottom: 4px;
}

.item {
    padding: 10px 0 20px;
    margin-bottom: 12px;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
}
.item .inputBox:last-child {
     margin-bottom: 0;
}

.addQuestion {
    width: 100%;
    padding-top: 16px;
    text-align: center;
}

.handleItem {
    display: flex;
    justify-content: flex-end;

}

.option_item {
    column-gap: 24px;

}
.option_head {
    display: flex;
    column-gap: 24px;
    margin-bottom: 4px;
}
.option_main {
    display: flex;
    column-gap: 24px;
    align-items: center;
    margin-bottom: 12px;
}
.option_title {
    width: 300px;
}

.footer {
    padding-left: 16px;
}
