

.inputBox {
    margin: 16px 0;
}
.label {
    margin-bottom: 4px;
}


