

.main {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    margin: 30px auto 0;
}

.row {
   margin-bottom: 12px;
}
.title {
    margin-bottom: 6px;
}
.record {
    height: 38px;
    padding-left: 1em;
    line-height: 38px;
    border-radius: 8px;
    background-color: #f5f5f5;
    cursor: pointer;
}
.box, .photo_list {
    max-height: 648px;
    padding-right: 16px;
    overflow-y: auto;
    
}
.box {
     margin-right: 16px;
}
.photo_list::-webkit-scrollbar {
    width: 5px;
}
.photo_list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .3);
}
.box::-webkit-scrollbar {
    width: 5px;
}
.box::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .3);
}
.photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 168px;
    margin-top: 12px;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
}
.item {
    margin-bottom: 24px;
}
