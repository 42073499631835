
.container {
    height: 100%;
}
.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 16px 0;
    font-size: 18px;
}
.inputBox {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 16px 0;
}
.inputBox1 {
    align-items: flex-start;
}
.item {
    width: 100%;
}
.label {
    width: 88px;
    flex-shrink: 0;
    text-align: right;
}
.quillBox {
   height: 320px;
}

.query {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 14px;
}
.query_input {
    display: flex;
    align-items: center;
    margin-right: 24px;

}
