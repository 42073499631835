.info {
    padding: 10px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
}
.info:hover {
    background-color: #f7f7f7;
}
.avatar {
    width: 26px;
    height: 26px;
}
.avatar img{
    width: 100%;
    height: auto;
}
.formBox {
    padding: 16px 0;
}
