
.richText {
    height: 100%;
}
.container {
    width: 100%;
    height: calc(100% - 42px);
    font-size: 16px;
   
    border: 2px solid #ccc!important;
}
.container p {
    line-height: 1.8;
}
.container img, video {
    display: block;
    width: 640px;
    height: auto;
    margin: 14px auto;
}
:global(.ql-editor)::-webkit-scrollbar {
    width: 6px;
}

:global(.ql-editor)::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(35, 42, 52, .3);
}

:global(.ql-toolbar.ql-snow)  {
    border: 2px solid #ccc!important;
    border-bottom: none!important;
}

