
html,body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, #root {
  width: 100%;
  height: 100vh;

}
html,body,p,h1,h2,h3,h4,h5,h6,ul,ol,li {
  margin: 0;
  padding: 0;
}
div {
    box-sizing: border-box;
}
li {
  list-style: none;
}
.ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .3);
  border-radius: 6px;
}
